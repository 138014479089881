<template>
  <div class="footer-main">
    <div class="copyright">
      © 2022 - 2023,
      <span
        @click="openUrl('/Certificate.pdf')"
      >
        «Autentic LTD»
      </span>
    </div>
    <div class="about">
      <span
        class="info"
        @click="openUrl('https://paladinsec.co/projects/autentic-utility-token/')"
      >
        {{ $t("footer.tokenAbout") }}
      </span>
      <!--<span
        class="info"
        @click="openUrl('https://aut.finance/')"
      >
        {{ $t("footer.services") }}
      </span>
      <span
        class="info"
        @click="openUrl('/wp_en.pdf')"
      >
        {{ $t("footer.conditions") }}
      </span>-->
    </div>
    <div class="confidence">
      <img src="/img/cabinet/docs.svg">
      <span
        class="info"
        @click="openUrl('/privacy_policy.pdf')"
      >
        {{ $t("footer.privacy") }}
      </span>
    </div>
    <div class="socials">
      <AuIcon link="https://www.linkedin.com/company/autcapital">
        <img
          class="icon"
          src="/img/cabinet/linkedin.svg"
          alt="linkedin"
        >
      </AuIcon>
      <AuIcon link="https://discord.com/invite/aZVj8q2hmT">
        <img
          class="icon"
          src="/img/cabinet/discord.svg"
          alt="discord"
        >
      </AuIcon>
      <AuIcon link="https://t.me/AUT_Community_EN">
        <img
          class="icon"
          src="/img/cabinet/telegram.svg"
          alt="viber"
        >
      </AuIcon>
      <AuIcon link="https://twitter.com/AutCapital">
        <img
          class="icon"
          src="/img/cabinet/x.svg"
          alt="x"
        >
      </AuIcon>
    </div>

    <img
      class="image"
      src="/img/cabinet/man-medium.png"
    >
  </div>
</template>

<script setup>

const openUrl = link => {
  window.open(link, "_blank");
};
</script>

<style scoped lang="scss">
.footer {
  @media (max-width: 1600px) {
    display: flex;
  }
}

.image {
  display: none;

  position: absolute;
  right: 0;

  @media (max-width: 1600px) {
    display: block;
  }

  @media (max-width: 800px) {
    width: 247px;
  }
}

.footer-main {
  width: 100%;
  height: 156px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  padding: 0 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 400;

  @media (max-width: 1600px) {
    padding: 0 0 0 60px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    height: 100%;
    margin-top: 90px;

    img {
      &.icon {
        width: 43px;
        height: 43px;
      }
    }
  }

  @media (max-width: 800px) {
    font-size: 13px;
    padding: 0 0 0 20px;
    gap: 15px;
    margin-top: 50px;

    img {
      &.icon {
        width: 28px;
        height: 28px;
      }
    }
  }

  .copyright {
    color: #1F3A43;
    cursor: pointer;
  }

  .about {
    display: flex;
    gap: 20px
  }

  .confidence {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      @media (max-width: 1600px) {
        height: 25px;
      }

      @media (max-width: 800px) {
        height: 20px;
      }
    }
  }

  .info {
    color: #2ABA7C;
    cursor: pointer;
  }

  .socials {
    display: flex;
    gap: 25px;

    @media (max-width: 800px) {
      margin-top: 10px;
      gap: 15px;
    }
  }

  .icon {
    width: 36px;
    height: 36px;
  }
}
</style>
