<template>
  <section class="anchors">
    <div class="container">
      <a @click="scrollTo('about')">
        {{ $t("anchorsSection.about") }}
      </a>
      <img :src="dotSrc">
      <a @click="scrollTo('products')">
        {{ $t("anchorsSection.products") }}
      </a>
      <img :src="dotSrc">
      <a @click="scrollTo('distribution')">
        {{ $t("anchorsSection.tokenDistribution") }}
      </a>
      <img :src="dotSrc">
      <a @click="scrollTo('platform')">
        {{ $t("anchorsSection.autenticPlatform") }}
      </a>
      <img :src="dotSrc">
      <a @click="scrollTo('calendar')">
        {{ $t("anchorsSection.ICOCalendar") }}
      </a>
      <img :src="dotSrc">
      <a @click="scrollTo('roadmap')">
        {{ $t("anchorsSection.roadmap") }}
      </a>
      <img :src="dotSrc">
      <a @click="scrollTo('referral')">
        {{ $t("anchorsSection.referralProgram") }}
      </a>
      <img :src="dotSrc">
      <a @click="scrollTo('team')">
        {{ $t("anchorsSection.teamAndEdvisers") }}
      </a>
      <img :src="dotSrc">
      <a @click="scrollTo('media')">
        {{ $t("anchorsSection.media") }}
      </a>
    </div>
  </section>
</template>

<script setup>
const emits = defineEmits(["scrollTo"]);

const dotSrc = "/img/anchors/dot.svg";

const scrollTo = refName => {
  emits("scrollTo", refName);
};
</script>

<style lang="scss" scoped>
.anchors {
  background: #1F3A43;
  height: 102px;

  position: relative;

  display: flex;
  align-items: center;
}

.container {
  display: flex;
  justify-content: space-between;

  width: 100%;
  margin-bottom: 0px;

  a {
    color: #4FE9A6;
    font-size: 18px;
    font-family: Inter;
    text-decoration: none;
    cursor: pointer;
  }
}
</style>