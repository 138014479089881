<template>
  <div v-if="!$route.meta.isMain">
    <RouterView />
    <ModalsContainer />
  </div>
  <main
    v-else
    class="main-page"
  >
    <img
      class="circles-top"
      src="/img/circles/header-circles.svg"
    >
    <Header ref="about" />
    <HeaderSection
      :show-modal="showModal"
    />
    <TrustedSection />
    <VideoSection />
    <ProductsSection ref="products" />
    <TokensSection ref="distribution" />
    <!-- <MechanismSection /> -->
    <SchemeSection ref="platform" />
    <AdvantagesSection ref="calendar" />
    <RoadmapSection ref="roadmap" />
    <ReferralSection ref="referral" />
    <TeamSection ref="team" />
    <div class="divider" />
    <MassMediaSection ref="media" />

    <div class="white-background">
      <ContractSection />
      <div class="divider" />
      <FAQSection />
      <AnchorsSection @scrollTo="scrollTo" />
      <Footer />
      <img
        class="circles-bottom"
        src="/img/circles/footer-circles.svg"
      >
    </div>

    <ModalsContainer />
    <Cookie
      v-if="showCoockie"
      @accept="accept"
      @close="close"
    />
  </main>
</template>

<script setup>
import axios from "axios";
import { computed, inject, onMounted, provide, ref } from "vue";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/vue";
import { ModalsContainer } from "vue-final-modal";
import { useHead } from "@unhead/vue";

import Cookie from "@/components/Cookie.vue";

import AdvantagesSection from "@/sections/AdvantagesSection.vue";
import AnchorsSection from "@/sections/AnchorsSection.vue";
import ContractSection from "@/sections/ContractSection.vue";
import FAQSection from "@/sections/FAQSection.vue";
import Footer from "@/sections/Footer.vue";
import Header from "@/sections/Header.vue";
import HeaderSection from "@/sections/HeaderSection.vue";
import MassMediaSection from "@/sections/MassMediaSection.vue";
// import MechanismSection from "@/sections/MechanismSection.vue";
import ProductsSection from "@/sections/ProductsSection.vue";
import ReferralSection from "@/sections/ReferralSection.vue";
import RoadmapSection from "./sections/RoadmapSection.vue";
import SchemeSection from "./sections/SchemeSection.vue";
import TeamSection from "@/sections/TeamSection.vue";
import TokensSection from "@/sections/TokensSection.vue";
import TrustedSection from "@/sections/TrustedSection.vue";
import VideoSection from "@/sections/VideoSection.vue";

const showCoockie = ref(true);

const runPoptin = () => {
  if (!document.cookie.includes("_no_poptin")) {
    useHead({
      script: [{
        id: "pixel-script-poptin",
        src: "https://cdn.popt.in/pixel.js?id=b2330b6853325",
        async: true
      }]
    });
  }
};

const getRefId = () => {
  let refId = document.cookie.match(/ref_id=[a-zA-Z0-9]{1,}/);
  if (refId) {
    refId = refId[0].slice(7);
  }
  return refId;
};

const incRefView = refId => {
  try {
    axios.get(`api/users/increment_ref_view/${refId}`);
  }
  catch (e) {
    console.log(e);
  }
};

const $cookies = inject("$cookies");

const accept = () => {
  $cookies.set("cookiesEnabled", true, "3153600000d");
  showCoockie.value = false;
};

const close = () => {
  showCoockie.value = false;
};

const groupAB = computed(() => {
  let ret = $cookies.get("autAB");
  if (!ret) {
    ret = Math.floor(Math.random() * 2);
    $cookies.set("autAB", ret, "3153600000d");
  }
  return ret;
});
const initAB = () => {
  provide("groupAB", groupAB.value);
  const typeAB = groupAB.value == 0 ? "header1" : "header2";
  window.ym(95179362, "reachGoal", typeAB);
  window.gtag("event", typeAB);
};

const initWeb3Modal = () => {
  const projectId = "8a93afa8fa43d9dcf5b589ac25a82eca";

  const ethereum = {
    chainId: 1,
    name: "Ethereum Mainnet",
    currency: "ETH",
    rpcUrl: "https://cloudflare-eth.com",
    explorerUrl: "https://etherscan.io"
  };

  const avax = {
    chainId: 43114,
    name: "Avalanche C-Chain",
    currency: "AVAX",
    rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
    explorerUrl: "https://snowtrace.io"
  };

  const bsc = {
    chainId: 56,
    name: "BNB Smart Chain Mainnet",
    currency: "BNB",
    rpcUrl: "https://bsc-dataseed.binance.org/",
    explorerUrl: "https://bscscan.com/"
  };

  const metadata = {
    name: "Autentic",
    description: "Autentic",
    url: "https://aut.finance/",
    icons: ["https://aut.finance/logo.png"]
  };

  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [bsc, avax, ethereum],
    projectId,
    enableAnalytics: true,
    featuredWalletIds: window?.ethereum?.isMetaMask ?
      [] : ["c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96"],
    themeVariables: {
      "--w3m-z-index": 10000
    }
  });
};

const showModal = ref(0);
onMounted(() => {
  let refId = window.location.href.match(/ref=[a-zA-Z0-9]{1,}/);
  if (refId) {
    refId = refId[0].slice(4);
    if (refId != getRefId()) {
      document.cookie = `ref_id=${refId};max-age=3153600000`;
      incRefView(refId);
    }
  }
  if (/pay=1/.test(window.location.href)) {
    showModal.value = 1;
  }

  const cookiesEnabled = $cookies.get("cookiesEnabled");

  if (cookiesEnabled) {
    showCoockie.value = false;
  }

  initWeb3Modal();
  runPoptin();
  initAB();
});

const about = ref(null);
const products = ref(null);
const distribution = ref(null);
const platform = ref(null);
const calendar = ref(null);
const roadmap = ref(null);
const referral = ref(null);
const team = ref(null);
const media = ref(null);

const refs = {
  about, products, distribution, platform, calendar, roadmap, referral, team, media
};

const scrollTo = refName => {
  if (refs[refName]) {
    console.log(refs[refName].value);
    console.log(refs[refName].value.$el);
    refs[refName].value.$el.scrollIntoView({ behavior: "smooth" });
  }
};
</script>

<style lang="scss">
.divider {
  margin: 170px 0px 100px 0px;
  width: 100%;
  background: #CCE3EB;
  height: 1px;
}

.white-background {
  background: #FFFFFF;
}

body {
  &:has(.popper) {
    overflow: hidden;
  }

  &:has(.side-menu) {
    overflow: hidden;
  }
}

.main-page {
  position: relative;
  overflow: hidden;
  background: linear-gradient(to left, #DAEDF3, #FFFFFF 75%);

  &:has(.popper) {
    @media screen and (min-width: 800px) {
      padding-right: 17px;
    }
  }
}

.circles {
  &-top {
    position: absolute;
    left: 0px;
    top: 0px;
    pointer-events: none;

    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
  &-bottom {
    position: absolute;
    right: 0px;
    bottom: 0px;
    pointer-events: none;
    opacity: 0.5;
  }
}

.container {
  padding: 0 150px;

  @media screen and (max-width: 1800px) {
    padding: 0 100px;
  }

  @media screen and (max-width: 1200px) {
    padding: 0 30px;
  }
}
</style>
