<template>
  <div>
    <div class="ref-panel-setting">
      <div class="left-panel">
        <h3 class="ref-title">
          {{ $t("settings.profile") }}
        </h3>

        <AuInput
          v-model="iName"
          class="mr"
          color="grey"
          :placeholder="$t('settings.iName')"
        />
        <AuInput
          v-model="fName"
          class="mr"
          color="grey"
          :placeholder="$t('settings.fName')"
        />
        <div class="btn-center">
          <AuButton
            class="mr"
            width="100%"
            center
            font-size="1.5em"
            :disabled="!checkFIO"
            @click="changeFIO"
          >
            {{ $t("settings.saveChanges") }}
          </AuButton>
        </div>

        <div class="group">
          <div class="text">
            {{ $t("settings.withdrawAdr") }}
          </div>

          <AuInput
            v-model="eth"
            type="Mask"
            :mask="/^[0xa-fA-F0-9]+$/i"
            placeholder-color="#B3D0DA"
            color="#1F3A43"
            icon="binance"
            placeholder="0х00000000000000000000000"
          />

          <div class="metamask-pos">
            <img
              class="this-ico"
              src="/img/modal/this.svg"
            >
            <button
              class="metamask-button"
              @click="getFromAdr"
            >
              <img
                class="metamask-ico"
                src="/img/modal/metamask.svg"
              >
              {{ $t("tokenBuy.getAdrFromMetamask") }}
            </button>
          </div>
        </div>
        <AuButton
          padding="10px 0"
          width="100%"
          class="mr"
          font-size="1em"
          center
          :disabled="!checkETH"
          @click="saveAndValidate"
        >
          <img
            class="metamask-ico"
            src="/img/modal/metamask.svg"
          >
          {{ $t("settings.validateAdr") }}
        </AuButton>
        <hr>

        <h3 class="ref-title-password">
          {{ $t("settings.changePassword") }}
        </h3>
        <AuInput
          v-model="passwordOld"
          class="mr"
          color="grey"
          type="Password"
          :placeholder="$t('settings.oldPassword')"
          icon="password"
          password-icon-type="dark"
        />
        <AuInput
          v-model="passwordNew1"
          class="mr"
          color="grey"
          type="Password"
          :placeholder="$t('settings.newPassword')"
          icon="password"
          password-icon-type="dark"
        />
        <label
          v-if="!$filters.correctPassword(passwordNew1) && passwordOld?.length > 0"
          for="password1"
          class="text-error"
        >{{ $t("settings.lengthPassword") }}</label>
        <label
          v-if="!$filters.correctPassword(passwordNew1) && passwordOld?.length > 0"
          for="password11"
          class="text-error"
        >{{ $t("settings.upperPassword") }}</label>
        <AuInput
          v-model="passwordNew2"
          class="mr"
          color="grey"
          type="Password"
          :placeholder="$t('settings.repeatPassword')"
          icon="password"
          password-icon-type="dark"
        />
        <label
          v-if="!identicalPassword"
          for="password2"
          class="text-error"
        >{{ $t("settings.errorPassword") }}</label>
        <div class="btn-center">
          <AuButton
            class="mr"
            width="100%"
            font-size="1.5em"
            center
            :disabled="!checkPassword"
            @click="changePassword"
          >
            {{ $t("settings.saveChanges") }}
          </AuButton>
        </div>
        <hr>
        <h3 class="ref-title-password">
          2FA {{ is2FaEnabled ? $t("settings.twoFAOn") : $t("settings.twoFAOff") }}
        </h3>
        <div v-if="!is2FaEnabled">
          <div class="qr-contain">
            <img
              v-if="imgURL"
              :src="imgURL"
              class="qr-image"
            >
          </div>
          <div class="line2fa">
            {{ token2fa }}
          </div>
          <div class="text">
            {{ $t("settings.twoFAMessage") }}
          </div>
          <AuInput
            v-model="authCode"
            class="mr"
            color="grey"
            :placeholder="$t('settings.twoFAPlaceholder')"
          />
          <AuButton
            width="100%"
            class="mr"
            font-size="1.5em"
            center
            :disabled="!authCode"
            :isProgress="loading"
            @click="set2faStatus(true)"
          >
            {{ $t("settings.twoFAButton") }} 2FA
          </AuButton>
        </div>
      </div>

      <div class="right-panel">
        <img
          src="/img/cabinet/girl_settings.png"
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import auth from "@/auth";
import axios from "axios";
import QRCode from "qrcode";
import { computed, onMounted, ref } from "vue";
import { createToaster } from "@meforma/vue-toaster";
import { useI18n } from "vue-i18n";
import { Web3 } from "web3";

const { t } = useI18n({});
const toast = createToaster({
  position: "top",
  duration: 4000,
  dismissible: true
});

const eth = ref("");
let account = [];

const iName = ref("");
const fName = ref("");
const passwordOld = ref("");
const passwordNew1 = ref("");
const passwordNew2 = ref("");
const is2FaEnabled = ref(false);

const token2fa = ref(null);
const imgURL = ref(null);
const authCode = ref(null);
const loading = ref(false);

const getFromAdr = async () => {
  if (!window.ethereum) {
    toast.error(t("tokenBuy.metamaskNotFound"));
    return;
  }

  try {
    account = await window.ethereum.request({ method: "eth_requestAccounts" });
  }
  catch (e) {
    toast.error(t("tokenBuy.metamaskFailConnect"));
    return;
  }

  eth.value = account.at(0) ?? 0;
};

const getData = async () => {
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authMem?.token}`
    };
    const resp = await axios.get(`/api/users/${authMem?.id}`, {
      headers: headers
    });
    iName.value = resp.data?.iName;
    fName.value = resp.data?.fName;
    is2FaEnabled.value = resp.data?.is2FaEnabled;
  }
  catch (e) {
    console.log(e);
  }
};

const changeETH = async () => {
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      accept: "application/json",
      "Content-Type": "application/merge-patch+json",
      Authorization: `Bearer ${authMem?.token}`
    };
    const params = {
      ethAddress: eth.value
    };
    await axios.patch("/api/users/change", params, { headers });
    toast.success(t("settings.success"));
    getData();
  }
  catch (e) {
    if (e.response.status == 403) {
      toast.error(t("settings.error"));
    }
    else if (e.response.status == 400) {
      toast.error(t("settings.messError"));
    }
    else {
      toast.error(t("settings.something"));
    }
    console.log(e);
  }
};
const changeFIO = async () => {
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      accept: "application/json",
      "Content-Type": "application/merge-patch+json",
      Authorization: `Bearer ${authMem?.token}`
    };
    const params = {
      fName: fName.value,
      iName: iName.value
    };
    await axios.patch("/api/users/change", params, { headers });
    toast.success(t("settings.success"));
    getData();
  }
  catch (e) {
    if (e.response.status == 403) {
      toast.error(t("settings.error"));
    }
    else if (e.response.status == 400) {
      toast.error(t("settings.messError"));
    }
    else {
      toast.error(t("settings.something"));
    }
    console.log(e);
  }
};
const changePassword = async () => {
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      accept: "application/json",
      "Content-Type": "application/merge-patch+json",
      Authorization: `Bearer ${authMem?.token}`
    };
    const params = {
      oldPassword: passwordOld.value,
      newPassword: passwordNew1.value
    };
    await axios.patch("/api/users/change", params, { headers });
    toast.success(t("settings.success"));
    getData();
  }
  catch (e) {
    if (e.response.status == 403) {
      toast.error(t("settings.error"));
    }
    else if (e.response.status == 400) {
      toast.error(t("settings.messError"));
    }
    else {
      toast.error(t("settings.something"));
    }
    console.log(e);
  }
};

const validateEth = async () => {
  if (!window.ethereum) {
    toast.error(t("tokenBuy.metamaskNotFound"));
    return;
  }
  const web3 = new Web3(window.ethereum);
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      Authorization: `Bearer ${authMem?.token}`
    };
    let data = await axios.get("/api/users/get_validate_content", { headers });
    const signData = await web3.eth.personal.sign(data.data.content, eth.value, "");
    data = await axios.get("/api/users/sign_and_verify", {
      params: { signature: signData },
      headers: headers
    });
    if (data.data.result) {
      toast.success(t("settings.validateSuccess"));
    }
    else {
      toast.error(t("settings.validateError"));
    }
  }
  catch (e) {
    toast.error(t("settings.validateError"));
  }
};

const saveAndValidate = async () => {
  await changeETH();
  await validateEth();
};

const getQR = async () => {
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      Authorization: `Bearer ${authMem?.token}`
    };
    const resp = await axios.get("/api/users/create_token", { headers });
    if (resp.status == "200") {
      token2fa.value = resp.data.token2fa;
      imgURL.value = await QRCode.toDataURL(resp.data.url);
    }
  }
  catch (e) {
    console.log(e);
  }
};

const set2faStatus = async status => {
  loading.value = true;
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      Authorization: `Bearer ${authMem?.token}`
    };
    const params = {
      is2faEnabled: status,
      token2fa: token2fa.value,
      authCode: authCode.value
    };
    await axios.post("/api/users/set2fa", params, { headers });
    toast.success(t("settings.twoFASuccess"));
    await getData();
  }
  catch (e) {
    toast.error(t("settings.twoFAError"));
  }
  loading.value = false;
};

const checkETH = computed(() => {
  return eth.value?.length > 0;
});
const checkFIO = computed(() => {
  return iName.value?.length > 0 && fName.value?.length > 0;
});
const checkPassword = computed(() => {
  return identicalPassword.value && strongPassword.value;
});
const identicalPassword = computed(() => {
  return passwordNew1.value === passwordNew2.value;
});
const strongPassword = computed(() => {
  return /(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8}/.test(passwordNew1.value);
});

onMounted(async () => {
  await getData();
  if (!is2FaEnabled.value) {
    await getQR();
  }
});
</script>

<style lang="scss" scoped>
.ref-panel-setting {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 15px;
  padding: 40px;
  @media (max-width: 1000px) {
    justify-content: center;
  }
}
.ref-title {
  font-weight: 600;
  font-size: 56px;
  margin-bottom: 40px;
  @media screen and (max-width: 1000px) {
    font-size: 40px;
  }
}
.ref-title-password {
  margin-top: 2rem ;
  font-weight: 600;
  font-size: 38px;
  margin-bottom: 40px;
}
// .ref-panel-user {
//   display: flex;
//   align-items: center;
//   margin: 40px 0;
// }
.btn-center {
  display: flex;
  justify-content: center;
}
.mr {
  margin-top: 1.2rem;
  margin-bottom: 0.5rem;
}
.text-error {
  margin: 4px;
  display: flex;
  justify-content: center;
  color: red;
  font-size: 16px;
}
.metamask-button {
  width: 100%;
  height: 50px;
  background-color: #FFC289;
  border-radius: 10px;
  border: none;
}
.text {
  color: #1f3a43;
  font-size: 15px;
  text-align: center;
  margin: 30px 0 10px 0;
}
.right-panel {
  @media (max-width: 1000px) {
    display: none;
  }
}
.qr-contain {
  display: flex;
  justify-content: center;
}
.qr-image {
  max-width: 200px;
}
.left-panel {
  @media (max-width: 1000px) {
    width: 100%;
  }
}
.line2fa {
  width: 100%;
  word-wrap: break-word;
  text-align: center;
}
</style>
