<template>
  <input
    v-model="checked"
    type="checkbox"
    class="check"
    @change="$emit('change', checked)"
  >
</template>

<script>
export default {
  emits: ["change"],

  data() {
    return {
      checked: false
    };
  }

};
</script>

<style lang="scss" scoped>
.check {
  width: 20px;
  height: 20px;
  border-radius: 6px;
  vertical-align: middle;
  border: 1px solid #4fe9a6;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: relative;
  &:checked {
    &::before {
      content: "";
      width: 14px;
      height: 14px;
      border-radius: 4px;
      position: absolute;
      top: calc(50% - 14px / 2);
      right: calc(50% - 14px / 2);
      background: #4fe9a6;
      color: #fff;
    }
  }
}
</style>