<template>
  <div class="transaction-panel">
    <div class="title">
      {{ $t("lastTransaction.title") }}
    </div>
    <AuTable
      :columns="columns"
      :items="items"
    />
    <div
      class="footer"
      @click="goToTransaction"
    >
      {{ $t("lastTransaction.seeAll") }}
      <img
        class="arrow"
        src="/img/cabinet/arrow.svg"
        alt="arrow"
      >
    </div>
  </div>
</template>

<script setup>
import _ from "lodash";
import BigNumber from "bignumber.js";
import { computed } from "vue";
import { useI18n } from "vue-i18n/dist/vue-i18n";
import { useRouter } from "vue-router";

const props = defineProps({
  transactions: {
    type: Array,
    default: () => []
  }
});
const { t } = useI18n({});
const router = useRouter();

const columns = computed(() => {
  return [
    { title: t("lastTransaction.from"), name: "addressFrom" },
    { title: t("lastTransaction.to"), name: "addressTo" },
    { title: t("lastTransaction.amount"), name: "amount" },
    { title: t("lastTransaction.network"), name: "blockChain" },
    { title: t("lastTransaction.created"), name: "createdAt" },
    { title: t("transactions.type"), name: "type" }];
});
const items = computed(() => {
  return _.map(props.transactions, item => ({
    addressFrom: getShortAdr(item.addressFrom),
    addressTo: getShortAdr(item.addressTo),
    amount: getAmount(item.amount),
    blockChain: item?.blockChain,
    createdAt: getDate(item.createdAt),
    type: getType(item.type)
  }));
});

const getType = type => {
  switch (type) {
  case "referral_pay":
    return "Реферальные выплаты";
  case "collect_usdt":
    return "Перевод";
  default:
    return "Стейкинг";
  }
};

const getShortAdr = adr => {
  return /^0x[a-fA-F0-9]{40}$/.test(adr) ? adr.slice(0, 5) + "..." + adr.slice(-5) : "-";
};

const getAmount = amount => {
  const buf = BigNumber(amount);
  return buf.isNaN() ? "-" : buf.toString(10);
};

const getDate = createdAt => {
  if (_.isNil(createdAt)) {
    return;
  }
  const ret = new Date(createdAt);
  return ret.getDate() + "." + (ret.getMonth() + 1) + "." + ret.getFullYear() + " " +
    ret.getHours() + ":" + ret.getMinutes() + ":" + ret.getSeconds();
};

const goToTransaction = () => {
  router.push({ name: "Transactions" });
};
</script>

<style lang="scss">
.transaction-panel {
  width: 100%;
  box-sizing: border-box;
  border-radius: 15px;
  background: white;
  padding: 40px;

  @media screen and (max-width: 800px) {
    padding: 20px;
  }

  .title {
    font-weight: 600;
    font-size: 2vw;
    margin-bottom: 30px;

    @media (max-width: 800px) {
      font-size: 22px;
    }
  }

  th {
    font-size: 1vw;
  }

  .footer {
    margin-top: 50px;
    font-weight: 600;
    font-size: 1vw;
    color: #2ABA7C;
    display: flex;
    gap: 5px;
    justify-content: end;
    align-items: center;
    cursor: pointer;

    @media (max-width: 1600px) {
      font-size: 1.5vw;
    }

    @media (max-width: 800px) {
      font-size: 14px;
    }
  }

  .arrow {
    width: 26px;
  }
}
</style>
