<template>
  <VueFinalModal>
    <div class="w-main">
      <div class="w-head">
        <img
          class="w-close"
          src="/img/modal/close.svg"
          alt=""
          @click="$emit('close')"
        >
      </div>
      <div>
        <span
          v-if="isNeedChangeSettings"
          class="w-body"
        >
          {{ isDataLoading ? necessary : $t("withdraw.loading") }}
        </span>
        <div v-else>
          <div class="w-title">
            {{ $t("withdraw.title") }}
          </div>
          <div v-if="stage===1">
            <AuInput
              v-model="authCode"
              color="black"
              :placeholder="$t('withdraw.twoFAPlaceholder')"
              @input="checkInput"
            />
          </div>
          <div
            v-if="stage===2"
            class="w-input"
          >
            <AuInput
              v-model="emailCode"
              color="black"
              :placeholder="$t('withdraw.emailPlaceholder')"
            />
          </div>
          <div class="w-info">
            <span>
              {{ $t("withdraw.sum") }} - {{ total }}
            </span>
            <span>
              {{ $t("withdraw.address") }} - {{ address }}
            </span>
          </div>
        </div>
      </div>
      <div
        class="w-footer"
        :ready="!isNeedChangeSettings"
      >
        <AuButton
          v-if="stage===2"
          height="55px"
          font-size="24px"
          center
          :disabled="sendDisabled"
          @click="sendMail"
        >
          {{ $t("withdraw.send") }}
        </AuButton>
        <AuButton
          height="55px"
          font-size="24px"
          center
          :disabled="payDisabled"
          @click="doAction"
        >
          {{ isNeedChangeSettings ? $t("withdraw.toSettings") :
          stage === 1 ? $t("withdraw.next") : $t("withdraw.enroll")}}
        </AuButton>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import auth from "@/auth";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import { createToaster } from "@meforma/vue-toaster";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { VueFinalModal } from "vue-final-modal";

const toast = createToaster({
  position: "top",
  duration: 4000,
  dismissible: true
});
const router = useRouter();
const emit = defineEmits(["close"]);
const { t } = useI18n({});

const isDataLoading = ref(false);
const isAddressVerified = ref(null);
const balance = ref(null);
const address = ref("-");
const authCode = ref("");
const emailCode = ref("");
const stage = ref(1);
const sendDisabled = ref(false);
const total = computed(() => {
  return balance.value !== null ? balance.value.toFixed(2) : "-";
});


const isNeedChangeSettings = computed(() => {
  return !isAddressVerified.value || balance.value < 80;
});
const necessary = computed(() => {
  let ret = isAddressVerified.value ? "" : t("withdraw.validate");
  ret = balance.value >= 80 ? ret : t("withdraw.min10");
  return ret;
});
const payDisabled = computed(() => {
  if (isNeedChangeSettings.value) {
    return false;
  }
  switch (stage.value) {
  case 1: return (authCode.value === "" || authCode.value.length < 6);
  case 2: return (emailCode.value === "");
  }
  return true;
});


const checkInput = e => {
  if (e.target.value.length === 6) {
    doAction();
  }
};

const getData = async () => {
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authMem?.token}`
    };
    const resp = await axios.get(`/api/users/${authMem?.id}`, {
      headers: headers
    });
    isAddressVerified.value = resp.data?.isAddressVerified;
    stage.value += !resp.data?.is2FaEnabled ? 1 : 0;

    balance.value = Number(resp.data?.mainBalance) + Number(resp.data?.referralBalance);
    address.value = resp.data?.ethAddress;
    if (/^0x[a-fA-F0-9]{40}$/.test(address.value)) {
      address.value = address.value.slice(0, 5) + "..." + address.value.slice(-5);
    }
  }
  catch (e) {
    console.log(e);
  }
  isDataLoading.value = true;
};

const sendMail = async () => {
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      Authorization: `Bearer ${authMem?.token}`
    };
    await axios.get("/api/users/send_mail_confirmation_code", { headers });
    toast.success(t("withdraw.mailSuccess"));
    sendDisabled.value = true;
    setTimeout(() => sendDisabled.value = false, 60000);
  }
  catch (e) {
    toast.error(t("withdraw.mailError"));
  }
};

const sendPayRequest = async () => {
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authMem?.token}`
    };
    const params = {
      mailCode: emailCode.value
    };
    await axios.post("/api/users/pay_request", params, { headers });
    toast.success(t("withdraw.requestSuccess"));
  }
  catch (e) {
    if (e.response.data.errors == "Your request already in process!") {
      toast.success("withdraw.alreadyInProcess");
    }
    else {
      toast.error(t("withdraw.requestError"));
    }
  }
};

const check2faCode = async () => {
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authMem?.token}`
    };
    const params = {
      code2fa: authCode.value
    };
    await axios.post("/api/users/confirm_2fa_code", params, { headers });
    return 1;
  }
  catch (e) {
    toast.error(t("withdraw.codeError"));
    return 0;
  }
};

const checkMailCode = async () => {
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authMem?.token}`
    };
    const params = {
      mailCode: emailCode.value
    };
    await axios.post("/api/users/confirm_mail_code", params, { headers });
    return true;
  }
  catch (e) {
    toast.error(t("withdraw.codeError"));
    return false;
  }
};

const doAction = async () => {
  if (isNeedChangeSettings.value) {
    router.push({ name: "settings" });
    emit("close");
  }
  else {
    switch (stage.value) {
    case 1:
      stage.value = stage.value + await check2faCode();
      break;
    case 2:
      if (await checkMailCode()) {
        await sendPayRequest();
        emit("close");
      }
      break;
    }
  }
};

onMounted(async () => {
  await getData();
});
</script>

<style scoped lang="scss">
.w-main {
  background: azure;
  border-radius: 10px;
  padding: 40px;
  width: 490px;
  @media screen and (max-width: 700px) {
    width: 230px;
  }
}
.w-head {
  display: flex;
  justify-content: end;
  position: relative;
}
.w-close {
  position: absolute;
  right: -75px;
  top: -55px;
  cursor: pointer;
}
.w-body {
  font-size: 30px;
  @media screen and (max-width: 700px) {
    font-size: 20px;
    font-weight: 500;
  }
}
.w-footer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px
}
.w-title {
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}
.w-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
</style>
