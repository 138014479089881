<template>
  <div class="token-info-main">
    <div class="header">
      Token AUT:
      <img
        class="header_logo"
        src="/img/cabinet/binance.svg"
        alt="binance"
      >
    </div>
    <div class="pay-block">
      <div class="pay-info">
        <div class="wallet">
          <div class="title">
            {{ $t("tokenInfo.address") }}
          </div>
          <div class="text">
            0x9e14C36896Daf970AE77A03E157e2dd3d0577c5b
          </div>
          <AuButton
            center
            font-size="14px"
            height="60px"
            width="fit-content"
            padding="7px 28px"
            @click="addToMetamask"
          >
            <img src="/img/cabinet/metamask.svg">
            {{ $t("tokenInfo.addButton") }}
          </AuButton>
        </div>
      </div>
      <img
        class="paladin"
        src="/img/cabinet/paladin.svg"
        alt="paladin"
        @click="goToPaladin"
      >
    </div>
  </div>
</template>

<script setup>

import { createToaster } from "@meforma/vue-toaster";
import { useI18n } from "vue-i18n";

const goToPaladin = () => {
  window.open("https://paladinsec.co/projects/autentic-utility-token/", "_blank");
};

const { t } = useI18n({});
const toast = createToaster({
  position: "top",
  duration: 4000,
  dismissible: true
});

const addToMetamask = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: "0x9e14C36896Daf970AE77A03E157e2dd3d0577c5b",
          symbol: "AUT",
          decimals: 18,
          image: "https://aut.finance/img/cabinet/aut-metamask.png"
        }
      }
    });
    toast.success(t("tokenInfo.success"));
  }
  catch (e) {
    toast.error(t("tokenInfo.error"));
  }
};
</script>

<!-- <style lang="scss">
  button.buy-btn {
    width: 100% !important;
  }
</style> -->

<style scoped lang="scss">
.token-info-main {
  width: 100%;
  box-sizing: border-box;
  padding: 40px;
  border-radius: 15px;
  background-color: #FFFFFF;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 30px;
  box-shadow: 5px 4px 6px #D2EDEB;
  box-shadow: 30px 26px 26px -5px #D2EDEB;
  box-shadow: 53px 43px 45px #D2EDEB;

  @media screen and (max-width: 800px) {
    padding: 20px;
  }

  .pay-info {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 20px;

    @media screen and (min-width: 1600px) {
      justify-content: center;
      align-items: center;
    }

    @media screen and (max-width: 800px) {
      justify-content: center;
      align-items: center;
    }
  }

  .pay-block {
    @media (max-width: 1600px) and (min-width: 800px) {
      display: flex;
      justify-content: space-between;
    }
  }

  .wallet {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media screen and (min-width: 1600px) {
      justify-content: center;
      align-items: center;
    }

    @media screen and (max-width: 800px) {
      justify-content: center;
      align-items: center;
    }
  }

  .header {
    display: flex;
    align-items: center;
    font-size: 38px;

    @media screen and (min-width: 1600px) {
      justify-content: center;
    }

    @media screen and (max-width: 1600px) {
      font-size: 3.5vw;
      margin-bottom: 1vw;
    }

    @media screen and (max-width: 800px) {
      font-size: 22px;
      margin-bottom: 20px;
      justify-content: center;
    }
  }
  .header_logo {
    margin-left: 10px;
  }

  .title {
    font-size: 1.5vw;

    @media screen and (max-width: 1600px) {
      font-size: 2.5vw;
    }

    @media screen and (max-width: 800px) {
      font-size: 18px;
    }
  }

  .text {
    font-size: 1vw;

    @media screen and (max-width: 1600px) {
      font-size: 1.5vw;
    }

    @media screen and (max-width: 800px) {
      font-size: 12px;
    }
  }

  .paladin {
    cursor: pointer;

    @media screen and (max-width: 1600px) {
      width: 30vw;
    }

    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }
}
</style>
