<template>
  <section class="container">
    <iframe
      height="747px"
      width="100%"
      src="https://www.youtube.com/embed/5NRXB8K4I2Q?&autoplay=1&mute=1"
      frameborder="0"
      allowfullscreen
    />
  </section>
</template>

<style lang="scss" scoped>
.container {
  position: relative;

  background: #000000;
  padding: 100px 400px;

  display: flex;
  justify-content: center;
}
</style>