<template>
  <div class="left-menu-main">
    <div
      class="item"
      :active="activeIndex===0"
      @click="goToDashboard"
    >
      <img :src="getIconName('dashboard', activeIndex===0)">
      {{ $t("leftMenu.dashboard") }}
    </div>
    <div
      class="item"
      :active="activeIndex===1"
      @click="openModal"
    >
      <img :src="getIconName('buy', activeIndex===1)">
      {{ $t("leftMenu.buy") }}
    </div>
    <div
      class="item"
      :active="activeIndex===2"
      @click="goToTransactions"
    >
      <img :src="getIconName('transaction', activeIndex===2)">
      {{ $t("leftMenu.transaction") }}
    </div>
    <div
      class="item"
      :active="activeIndex===3"
      @click="goToReferralInfo"
    >
      <img :src="getIconName('referrals', activeIndex===3)">
      {{ $t("leftMenu.referral") }}
    </div>
    <div
      class="item"
      :active="activeIndex===4"
      @click="goToStaking"
    >
      <img :src="getIconName('staking', activeIndex===4)">
      {{ $t("leftMenu.staking") }}
      <span
        v-if="showPopper[0]"
        class="custom-popper"
      >
        {{ popper }}
      </span>
    </div>
    <div
      class="item"
      :active="activeIndex===5"
      @click="goToVesting"
    >
      <img :src="getIconName('vesting', activeIndex===5)">
      {{ $t("leftMenu.vesting") }}
    </div>
    <div
      class="item"
      :active="activeIndex===6"
      @click="goToTutorial"
    >
      <img :src="getIconName('tutorials', activeIndex===6)">
      {{ $t("leftMenu.tutorials") }}
    </div>
    <div
      class="item"
      :active="activeIndex===7"
      @click="goToSupport"
    >
      <img :src="getIconName('support', activeIndex===7)">
      {{ $t("leftMenu.support") }}
    </div>
    <div
      class="item"
      :active="activeIndex===8"
      :end="true"
      @click="goToSettings"
    >
      <img :src="getIconName('settings', activeIndex===8)">
      {{ $t("leftMenu.settings") }}
    </div>
  </div>
</template>

<script setup>
import TokensBuy from "@/components/TokensBuy";
import TokensBuyOld from "@/components/TokenBuyOld";
import { computed, defineProps, inject, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useModal } from "vue-final-modal";
import { useRoute, useRouter } from "vue-router";
const { t } = useI18n({});

const props = defineProps({
  address: {
    type: String,
    default: ""
  },
  email: {
    type: String,
    default: ""
  }
});
const popper = computed(() => {
  return t("poppers.soon");
});
const route = useRoute();
const router = useRouter();
let activeIndex = ref(route.meta?.menuId ?? 0);
let showPopper = ref([false, false]);
watch(route, newValue => {
  activeIndex.value = newValue.meta?.menuId;
});
const getIconName = (iconName, isActive) => {
  return "/img/cabinet/" + iconName + (isActive ? "_active.svg" : ".svg");
};

const goToDashboard = () => {
  activeIndex.value = 0;
  router.push({ name: "dashboard" });
};
const goToTransactions = () => {
  activeIndex.value = 2;
  router.push({ name: "Transactions" });
};
const goToReferralInfo = () => {
  activeIndex.value = 3;
  router.push({ name: "referral info" });
};
const goToStaking = () => {
  activeIndex.value = 4;
  router.push({ name: "staking" });
};
const goToTutorial = () => {
  activeIndex.value = 6;
  router.push({ name: "tutorial" });
};
const goToVesting = () => {
  activeIndex.value = 5;
  router.push({ name: "vesting" });
};
const goToSupport = () => {
  activeIndex.value = 7;
  window.open("https://t.me/+i0KV3Kwmt7dhMDEy", "_blank").focus();
};
const goToSettings = () => {
  activeIndex.value = 8;
  router.push({ name: "settings" });
};

const groupAB = inject("groupAB");
const openModal = () => {
  const component = groupAB == 1 ? TokensBuy : TokensBuyOld;
  const { open, close } = useModal({
    component: component,
    attrs: {
      initEmail: props.email,
      initAddress: props.address,
      onClose() {
        close();
      }
    }
  });

  open();
};
</script>

<style scoped lang="scss">
.left-menu-main {
  width: 100%;
  box-sizing: border-box;
  padding: 0px 40px;
  background-color: #FFFFFF;
  border-radius: 15px;
  box-shadow: 5px 4px 6px #D2EDEB;
  box-shadow: 30px 26px 26px -5px #D2EDEB;
  box-shadow: 53px 43px 45px #D2EDEB;

  .item {
    position: relative;
    font-weight: 500;
    font-size: 26px;
    color: #096F57;
    border-bottom: 1px solid #DAEDF3;
    padding: 33px 0;
    display: flex;
    gap: 18px;
    align-items: center;
    cursor: pointer;
    &[active="true"] {
      color: #2ABA7C;
      border-bottom: 1px solid #2ABA7C;
    }
    &[end="true"]{
      border-bottom: none;
    }
  }
  .no-active {
    color: #ababab;
    border-bottom: 1px solid #ababab;
    &[active="true"] {
      color: #ababab;
      border-bottom: 1px solid #ababab;
    }
  }
}
.custom-popper {
  position: absolute;
  right: 0;
  background-color: #f8f8f8;
  padding: 10px 20px;
  border-radius: 12px;
  color: #9b9b9b;

}
</style>
