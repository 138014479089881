<template>
  <div>
    <HeaderRegister />
    <div class="reg-panel">
      <div class="left-panel">
        <div class="left-text-up">
          <h3 class="text-header">
            {{ $t("register.title") }}
          </h3>
          <p class="left-text-description">
            {{ $t("register.description") }}
          </p>
        </div>
        <img
          class="img"
          src="/img/pages/main_register.png"
        >
      </div>
      <div class="right-panel">
        <div class="right-text-up">
          <h3 class="text-header">
            {{ $t("register.button") }}
          </h3>
          <div class="cabinet-link">
            <img
              class="icon"
              src="/img/cabinet/person.svg"
            >
            <div class="link-info">
              <span
                class="sign"
                @click="goToLogin"
              >{{ $t("login.title") }}</span>
            </div>
          </div>
        </div>
        <div class="rigth-form">
          <span
            v-if="emailError.length > 0"
            class="error"
          >
            {{ emailError }}
          </span>
          <AuInput
            v-model="email"
            class="mr"
            color="grey"
            :placeholder="$t('register.email')"
            icon="email"
            :required="emailError.length > 0"
          />
          <AuInput
            v-model="password"
            class="mr"
            color="grey"
            type="Password"
            :placeholder="$t('register.password')"
            icon="password"
            password-icon-type="dark"
          />
          <AuInput
            v-model="confirmPassword"
            class="mr"
            color="grey"
            type="Password"
            :placeholder="$t('register.confirm')"
            icon="password"
            password-icon-type="dark"
          />
          <AuInput
            v-model="iName"
            class="mr"
            color="grey"
            :placeholder="$t('register.name')"
            icon="fullName"
          />
          <AuInput
            v-model="fName"
            class="mr"
            color="grey"
            :placeholder="$t('register.surname')"
            icon="fullName"
          />
          <p class="label-info">
            {{ $t("register.optional") }}
          </p>
          <AuInput
            id="referralId"
            v-model="referralId"
            color="grey"
            :placeholder="$t('register.refId')"
          />
          <div class="mr">
            <AuCheckbox
              class="au-checkbox"
              @change="checkChecked"
            /><span class="police">{{ $t("register.police_1") }} <a
              class="link"
              href="/doc/сookie_policy.pdf"
              target="_blank"
            >{{
              $t("register.police_2")
            }}</a> {{ $t("register.police_3") }} <a
              class="link"
              href="/doc/privacy_policy.pdf"
              target="_blank"
            >{{
              $t("register.police_4")
            }}</a></span>
          </div>
          <p class="error">
            {{ correct }}
          </p>
          <div class="btn-center">
            <AuButton
              class="mr"
              :is-progress="isProgress"
              @click="regIn"
            >
              {{ $t("register.button") }}
            </AuButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import auth from "@/auth";
import axios from "axios";
import HeaderRegister from "@/sections/HeaderRegister.vue";
import Trans from "@/localization/translation";
import { computed, onMounted, ref } from "vue";
import { createToaster } from "@meforma/vue-toaster";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { t } = useI18n({});
const router = useRouter();
const toast = createToaster({
  position: "top",
  duration: 4000,
  dismissible: true
});

const isProgress = ref(false);
const email = ref(null);
const password = ref(null);
const confirmPassword = ref(null);
const fName = ref(null);
const iName = ref(null);
const referralId = ref(null);
const progress = ref(null);
const ethError = ref("");
const emailError = ref("");
const checked = ref(false);

const checkChecked = value => {
  checked.value = value;
};

const correct = computed(() => {
  if (!progress.value) {
    return "";
  }
  if (!email.value || !password.value || !confirmPassword.value || !fName.value || !iName.value || !checked.value) {
    return t("register.empty");
  }
  if (password.value !== confirmPassword.value) {
    return t("register.mismatch");
  }
  if (password.value.length < 8) {
    return t("register.short");
  }
  if (!/[A-Z]/.test(password.value) || !/[a-z]/.test(password.value) || !/[0-9]/.test(password.value)) {
    return t("register.complexity");
  }
  return "";
});

const getRefId = () => {
  let refId = document.cookie.match(/ref_id=[a-zA-Z0-9]{1,}/);
  if (refId) {
    refId = refId[0].slice(7);
  }
  return refId;
};

const regIn = async () => {
  isProgress.value = true;
  progress.value = true;
  if (correct.value.length > 0) {
    isProgress.value = false;
    return;
  }
  const params = {
    email: email.value,
    password: password.value,
    fName: fName.value,
    iName: iName.value,
    userRefId: referralId.value
  };
  const headers = {
    accept: "application/json",
    "Content-Type": "application/json"
  };
  try {
    await axios.post("api/users/register", params, { headers });
    await logIn();
    isProgress.value = false;
  }
  catch (e) {
    isProgress.value = false;
    if (e.response.status == 400) {
      if (e.response.data.error == "User already exist!") {
        emailError.value = t("register.adrExist");
      }
      if (e.response.data.error == "Address already exists!") {
        ethError.value = t("register.ethExist");
      }
      toast.error(t(e.response.data.error));
      return;
    }
    toast.error(t("register.error"));
  }
};

const logIn = async () => {
  const data = {
    email: email.value,
    password: password.value
  };
  try {
    const resp = await auth.login({
      data,
      staySignedIn: true,
      fetchUser: false,
      url: "/api/users/login"
    });
    auth.remember(JSON.stringify({
      id: resp.data.data.id,
      username: email.value,
      token: resp.data.Authorization
    }));
    toast.success(t("login.success"));
    disablePoptin();
    await router.push({ name: "dashboard" });
  }
  catch (e) {
    if (e.response.data.errors == "User not confirmed!") {
      toast.error(t("login.errors.notConfirm"));
    }
    if (e.response.data.errors == "User not found!") {
      toast.error(t("login.errors.notFound"));
    }
    if (e.response.data.errors == "Invalid credentials!") {
      toast.error(t("login.errors.notCredentials"));
    }
    else {
      toast.error(t("login.error"));
    }
  }
};

const disablePoptin = () => {
  document.cookie = "_no_poptin=true;max-age=3153600000";
};

const goToLogin = () => {
  router.push({ name: "Login", params: { locale: Trans.currentLocale } });
};

onMounted(() => {
  const refId = getRefId();
  referralId.value = refId === 0 ? null : refId;
});
</script>

<style lang="scss" scoped>
.reg-panel {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 15px;
  padding: 40px;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    padding: 14px;
  }
}
.left-panel {
  max-width: 60%;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  margin: 20px;
  box-shadow: 53px 43px 45px 0px rgba(210, 237, 235, 0.20), 30px 26px 26px -5px rgba(210, 237, 235, 0.86), 5px 4px 6px 0px #D2EDEB;

  @media screen and (max-width: 800px) {
    max-width: 100%;
    width: 100%;
    margin: 4px;
  }
}
.left-text-up {
  padding: 40px;
}
.img {
  max-width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: 800px) {
    display: none;
  }
}

.right-panel {
  width: 38%;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  margin: 20px;
  box-shadow: 53px 43px 45px 0px rgba(210, 237, 235, 0.20), 30px 26px 26px -5px rgba(210, 237, 235, 0.86), 5px 4px 6px 0px #D2EDEB;

  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 4px;
  }
}
.right-text-up {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
}
.icon {
  width: 30px;
  opacity: 0.7;
}
.text-header {
  font-weight: bold;
  color: #1F3A43;
  font-size: 38px;
}
.left-text-description {
  margin-top: 30px;
  color: #1F3A43;
  font-size: 23px;
}
.rigth-form {
  margin: 0 40px;
}
.btn-center {
  display: flex;
  justify-content: center;
}
.mr {
  margin-top: 1.2rem;
  margin-bottom: 0.5rem;
}
.label-info {
  margin-top: 1.2rem;
  margin-bottom: 0.5rem;
}
.police {
  color: #1F3A43;
  line-height: 24px;
}
.au-checkbox {
  margin-right: 10px;
}
.error {
  color: red;
  text-align: center;
  margin-top: 10px;
}
.link {
  color: #2ABA7C;
  text-decoration: underline;
  cursor: pointer;
}
.cabinet-link {
  display: flex;
  gap: 10px;

  .link-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
  }
  .sign {
    color: #2ABA7C;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
