<template>
  <div>
    <div class="staking-title">
      {{ $t("staking.title") }}
    </div>
    <div class="staking-panel">
      <div class="staking-text">
        {{ $t("staking.balance") }} - {{ stakingBalance }}
      </div>
      <div class="staking-text">
        {{ $t("staking.percentMonth") }} - {{ stakingPercents }}
      </div>
      <div class="stacking-table">
        <div class="stacking-caption">
          {{ $t("staking.periodTitle") }}
        </div>
        <AuTable
          :columns="columns"
          :items="staking"
        />
        <AuPagination
          v-if="totalItems > itemsPerPage"
          :total-items="totalItems"
          :items-per-page="itemsPerPage"
          @changePage="changePage"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import _ from "lodash";
import auth from "@/auth";
import axios from "axios";
import BigNumber from "bignumber.js";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/dist/vue-i18n";

const stakingBalance = ref(0);
const stakingPercents = ref(0);
const itemsPerPage = ref(30);
let page = ref(1);
let staking = ref([]);
let totalItems = ref(0);
const { t } = useI18n({});

const columns = computed(() => {
  return [
    { title: t("staking.createdAt"), name: "createdAt" },
    { title: t("staking.amount"), name: "amount" }];
});

const getUserData = async () => {
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authMem?.token}`
    };
    const resp = await axios.get(`/api/users/${authMem?.id}`, {
      headers: headers
    });
    stakingBalance.value = BigNumber(resp.data?.autoStakingBalance ?? 0).toFixed(6);
    stakingPercents.value = BigNumber(resp.data?.autoStakingPercentsBalance ?? 0).toFixed(6);
  }
  catch (e) {
    console.log(e);
  }
};

const getStakingData = async () => {
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json",
      Authorization: `Bearer ${authMem?.token}`
    };
    const params = {
      page: page.value,
      itemsPerPage: itemsPerPage.value,
      userId: authMem?.id,
      type: "auto_cap"
    };
    const resp = await axios.get("/api/staking_transactions", {
      headers: headers,
      params: params
    });
    staking = ref(createStakingData(resp.data["hydra:member"]));
    totalItems.value = resp.data["hydra:totalItems"];
  }
  catch (e) {
    console.log(e);
  }
};

const createStakingData = arr => {
  return _.map(arr, item => {
    return {
      createdAt: formatDate(item.createdAt),
      amount: BigNumber(item.amount).toFixed(6)
    };
  });
};

const formatDate = str => {
  const buf = new Date(Date.parse(str));
  let ret = (buf.getDate() < 10 ? `0${buf.getDate()}` : buf.getDate) + ".";
  ret += (buf.getMonth() < 9 ? `0${buf.getMonth() + 1}` : buf.getMonth() + 1) + ".";
  return ret + buf.getFullYear();
};

const changePage = async activePage => {
  page.value = activePage;
  await getStakingData();
};

onMounted(async () => {
  await getUserData();
  await getStakingData();
});
</script>

<style scoped lang="scss">
.staking-title {
  font-weight: 600;
  font-size: 56px;
  margin-bottom: 40px;
}
.staking-panel {
  background-color: white;
  border-radius: 15px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: 800px) {
    padding: 20px;
  }
}
.staking-text {
  font-size: 24px;
}
.stacking-caption {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
}
.stacking-table {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
