<template>
  <div class="system-wrap">
    <div class="system">
      <div class="system-part system-big system-img">
        <img
          class="system-part decoration"
          src="/img/system/decoration.svg"
        >

        <img
          class="system-part"
          src="/img/system/pc.png"
        >
      </div>
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped lang="scss">
.system {
  position: relative;
  width: 632px;

  @media screen and (max-width: 800px) {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 420px;
  }

  &-wrap {
    width: 632px;
    margin-right: 200px;

    @media screen and (max-width: 800px) {
      width: auto;
    }
  }

  .system-big {

  }

  &-img {
    pointer-events: none;
  }

  &-part {
    position: absolute;

    &.decoration {
      top: -160px;
      left: -478px;
    }

    &.decoration-small {
      top: -23px;
    }
  }
}
</style>
