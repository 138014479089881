<template>
  <section class="mass-media-section container">
    <div class="section-content-wrapper">
      <h1>
        {{ $t("massMediaSection.header") }}
      </h1>
      <p>
        {{ $t("massMediaSection.text") }}
      </p>
      <div class="mass-media-swiper-wrapper">
        <Swiper
          class="mass-media-swiper"
          :pagination="{ clickable: true }"
          :slides-per-view="3"
          :space-between="50"
          :modules="modules"
        >
          <SwiperSlide
            v-for="(item, index) in massMedia"
            :key="index"
          >
            <div class="mass-media-item">
              <div class="image-wrapper">
                <img
                  :src="`/img/mass-media/${item.image}.svg`"
                  :alt="item.title"
                >
              </div>
              <div class="content-wrapper">
                <h3>
                  {{ item.title }}
                </h3>
                <p>
                  {{ item.text }}
                </p>
              </div>
              <a
                class="read-link"
                :href="item.link"
              >{{ $t("massMediaSection.read") }}</a>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div class="mass-media-swiper-wrapper-mobile">
        <div
          v-for="(item, index) in massMedia"
          :key="index"
          class="mass-media-item"
        >
          <div class="image-wrapper">
            <img
              :src="`/img/mass-media/${item.image}.svg`"
              :alt="item.title"
            >
          </div>
          <div class="content-wrapper">
            <h3>
              {{ item.title }}
            </h3>
            <p>
              {{ item.text }}
            </p>
          </div>
          <a
            class="read-link"
            :href="item.link"
          >{{ $t("massMediaSection.read") }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";

import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/css";
import "swiper/css/pagination";

const modules = ref([Pagination]);

const massMedia = ref([
  {
    image: "yahoo",
    title: "Yahoo! Finance",
    text:
      "Autentic, the ecosystem of digital financial assets, has announced its native token AUt presale for institutional and private funds, partners, and investment...",
    link: "https://finance.yahoo.com/private-token-sale-funds-partners-000000908.html"
  },
  {
    image: "binance",
    title: "Binance Square",
    text:
      "Autentic is a blockchain financial ecosystem that embraces several areas with its three major components. Namely, Autentic Capital allows...",
    link: "#"
  },
  {
    image: "bloomberg",
    title: "Bloomberg",
    text:
      "In total, Autentic plans to issue 150 million AUt, which will be used as an internal settlement for accessing platform services, paying fees, and serving as an...",
    link:
      "https://www.bloomberg.com/press-releases/2023-07-18/private-token-sale-for-funds-and-partners-from-autentic"
  },
  {
    image: "bitcoinist",
    title: "Bitcoinist.com",
    text:
      "October 15 (Victoria, Seychelles) – The financial blockchain ecosystem, Autentic, has been recognized as one of the top 15 most promising and forward...",
    link:
      "https://bitcoinist.com/autentic-capital-enters-the-top-15-promising-web-3-0-projects/"
  },
  {
    image: "coincodex",
    title: "Coincodex",
    text:
      "The Web 3.0 startup market is constantly evolving, creating hundreds of solutions and products every day. However, only a few startups truly become...",
    link:
      "https://coincodex.com/article/33380/how-the-web-30-startup-autentic-capital-became-one-of-the-most-promising-projects-in-the-digital-asset-market/"
  }
]);

</script>

<style>
:root {
  --swiper-theme-color: #1f3a43;
}
.swiper {
  padding-bottom: 70px;
}
.swiper-pagination {
  bottom: -2px;
}
.swiper-pagination-bullet {
  width: 13px;
  height: 13px;
}
.swiper-pagination-bullet-active {
  width: 17px;
  height: 17px;
}
</style>

<style lang="scss" scoped>
.mass-media-swiper-wrapper {
  @media screen and (max-width: 1200px) {
    display: none;
  }

  &-mobile {
    display: none;

    @media screen and (max-width: 1200px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.mass-media {
  &-item {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 513px;
    padding: 40px 30px 45px 30px;
    align-items: flex-start;
    gap: 10px;
    height: 508px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 53px 43px 45px 0px rgba(210, 237, 235, 0.2),
      30px 26px 26px -5px rgba(210, 237, 235, 0.86), 5px 4px 6px 0px #d2edeb;

    @media screen and (max-width: 1200px) {
      margin-bottom: 20px;
      width: 70vw;
      height: auto;
    }

    .image-wrapper {
      display: flex;
      padding: 37px 67.3px 36.8px 68px;
      justify-content: center;
      align-items: center;
      background: #f7fbfd;
      height: 180px;
      box-sizing: border-box;
      width: 100%;

      img {
        max-width: 26vw;

        @media screen and (max-width: 1200px) {
          max-width: 100vw;
        }
      }
    }
    div.content-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      h3 {
        font-weight: 600;
        font-size: 28px;
      }
      p {
        font-size: 17px;
        font-weight: 400;
        line-height: 26px;
        max-width: 420px;
      }
    }
    a.read-link {
      color: #2aba7c;
      font-size: 22px;
      font-weight: 400;
      line-height: 26px;
      text-decoration: none;
      position: absolute;
      bottom: 45px;
    }
  }
  &-section {
    display: flex;
    justify-content: center;
    flex-direction: column;

    padding-top: 60px;
    padding-bottom: 60px;

    .section-content-wrapper {
      color: #1f3a43;
      h1 {
        font-size: 68px;
        font-weight: 600;
        margin-bottom: 20px;
        max-width: 1037px;

        @media screen and (max-width: 1200px) {
          font-size: 26px;
          margin-top: 50px;
        }
      }
      p {
        line-height: 38px;
        font-weight: 500;
        font-size: 28px;
        max-width: 945px;
        margin-bottom: 50px;

        @media screen and (max-width: 1200px) {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
}
</style>
