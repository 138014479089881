<template>
  <header class="header container">
    <a
      class="link"
      href="/"
    >
    <img src="/img/cabinet/logo.svg">
  </a>
    <div class="lang-buttons">
      <div
        v-for="locale in supportedLocales"
        :key="locale"
        :selected="isLangSelected(locale)"
        class="lang-button"
        @click="switchLanguage(locale)"
      >
        <img :src="`/img/flags/${locale}.svg`">
        {{ locale.toUpperCase() }}
      </div>
    </div>

    <div class="lang-button_single">
      <AuSelect
        css-vars="--width: 160px"
        class="locale-switcher"
        :value="currentLocale"
        :placeholder="currentLocale.toUpperCase()"
        :options="countryOptions"
        :image="true"
        @input="(v) => switchLanguage(v)"
      />
    </div>

  </header>
</template>

<script setup>
import _ from "lodash";

import { useI18n } from "vue-i18n";
const { t } = new useI18n();

import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import Trans from "@/localization/translation";

const supportedLocales = ref(Trans.supportedLocales);

const router = useRouter();

const currentLocale = computed(() => {
  return Trans.currentLocale;
});
const countryOptions = computed(() => {
  return _.map(supportedLocales.value, l => {
    return {
      display: l.toUpperCase(),
      value: l,
      image: `/img/flags/${l}.svg`
    };
  });
});
document.title = t("title");

const switchLanguage = async newLocale => {
  await Trans.switchLanguage(newLocale);

  try {
    await router.replace({ params: { locale: newLocale } });
    document.title = t("title");
  }
  catch (e) {
    console.log(e);
    router.push("/");
  }
};

const isLangSelected = lang => {
  return lang == Trans.currentLocale;
};

</script>

<style lang="scss">
.locale-switcher {
  .multiselect {
    border: none;
    &__select {
      right: 18px;
      top: 5px;
    }
    &__single {
      color: white;
      font-size: 20px;
      div {
        display: flex;
        align-items: center;
        .picture {
          margin-right: 10px;
          height: 28px;
        }
      }
    }
    &__input {
      font-size: 20px;
      padding-left: 53px;
      padding-top: 6px;
      color: transparent;
    }
    &__content {
      &-wrapper {
        top: 50px;
        left: 0;
      }
    }
    &__element {
      font-size: 20px;
      padding-left: 0;
      .picture {
        height: 28px;
      }
    }
    &__option {
      padding: 7px 22px;
    }
  }
}
</style>

<style lang="scss" scoped>
.header {
  padding: 30px 80px;
  background: #1F3A43;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .lang-button {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 13px;
    opacity: 50%;

    &:hover {
      opacity: 80%;
    }

    &[selected="true"] {
      opacity: 100%;
    }

    &_single {
      display: none;
      z-index: 999;

      @media screen and (max-width: 1500px) {
        display: block;
        margin-top: -5px;
        margin-right: -20px;
      }
    }
  }

  .lang-buttons {
    display: flex;
    gap: 18px;
    cursor: pointer;

    @media screen and (max-width: 1500px) {
      display: none;
    }
  }
}
</style>
