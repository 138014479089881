<template>
  <section class="section container">
    <div class="info">
      <div class="right-block">
        <div class="description">
          <h1
            class="main-text"
            v-html="$t('headerSection.header')"
          />

          <h2 class="sub-text">
            {{ $t("headerSection.subHeader") }}
          </h2>

          <span class="text">
            <span v-html="$t('headerSection.text')" />
            <div class="achievements">
              <div class="holders">
                <div class="stars">
                  <img src="img/system/star.svg">
                  <img src="img/system/star.svg">
                  <img src="img/system/star.svg">
                  <img src="img/system/star.svg">
                  <img src="img/system/star.svg">
                </div>
                <span v-html="$t('headerSection.holders', { count: holdersCount })" />
              </div>

              <div>
                <span v-html="$t('headerSection.raised', { count: raisedCount })" />
              </div>
            </div>
          </span>
        </div>

        <div class="buttons">
          <AuButton
            padding="23px 60px"
            font-size="28px"
            @click="() => openModal(1)"
          >
            {{ $t("headerSection.buy") }}
          </AuButton>

          <a
            class="witepaper"
            href="wp_en.pdf"
            target="_blank"
          >
            Autentic Whitepaper.pdf<img src="img/system/arrow-down.svg">
          </a>
        </div>
      </div>
    </div>
    <HeaderSectionImage class="image" />
  </section>
</template>

<script setup>
import HeaderSectionImage from "@/components/HeaderSectionImage.vue";
import Presentation from "@/components/Presentation.vue";
import TokensBuyOld from "@/components/TokenBuyOld";
import Whitepaper from "@/components/Whitepaper.vue";

import { onMounted } from "vue";
import { useModal } from "vue-final-modal";
const openModal = type => {
  let component = null;
  if (type == 1) {
    component = TokensBuyOld;
  }
  else if (type == 2) {
    component = Presentation;
  }
  else {
    component = Whitepaper;
  }

  const { open, close } = useModal({
    component: component,
    attrs: {
      onClose() {
        close();
      }
    }
  });

  open();
};

const holdersCount = process.env.VUE_APP_HOLDERS;
const raisedCount = process.env.VUE_APP_RAISED;

const props = defineProps({
  showModal: {
    type: Number,
    default: 0
  }
});

onMounted(() => {
  if (props.showModal !== 0) {
    openModal(props.showModal);
  }
});
</script>

<style>
.bold {
  font-family: Inter Bold;
}

.green {
  font-family: Inter ExtraBold;
  color: #096F57;
}
</style>

<style lang="scss" scoped>
.section {
  display: flex;
  justify-content: space-between;

  margin-top: 100px;

  @media screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column-reverse;
  }
  @media screen and (max-width: 800px) {
    margin-top: -50px;
  }

  .image  {
    margin-top: 50px;
  }
}

.buttons {
  display: flex;
  align-items: center;

  gap: 50px;

  margin-top: 50px;
}

.achievements {
  display: flex;
  justify-content: space-between;

  font-size: 22px;
  font-family: Inter Medium;

  margin-top: 25px;

  .holders {
    display: flex;
    gap: 5px;

    .stars {
      display: flex;
      gap: 3px;

      margin-right: 10px;
    }
  }
}

.witepaper {
  color: #2ABA7C;
  font-size: 28px;
  font-family: 'Inter Medium';

  white-space: nowrap;

  a:visited  {
    color: #2ABA7C;
  }

  img {
    margin-left: 5px;
  }
}

.line-wrap {
  height: 70%;
  width: 50%;

  background-image: linear-gradient(rgba(0, 0, 0, 0) 33%, #19d88d 0%);
  background-position: right;
  background-size: 1px 15px;
  background-repeat: repeat-y;

  @media screen and (max-width: 1200px) {
    height: 80%;
  }
}

.info {
  display: flex;
  gap: 30px;

  @media screen and (max-width: 1200px) {
    margin-left: -30px;
    gap: 0px;
  }

  .aut {
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }

  .aut-small {
    display: none;

    font-size: 14px;

    @media screen and (max-width: 1200px) {
      display: block;
    }
  }
}

.description {
  display: flex;
  flex-direction: column;
  gap: 20px;

  max-width: 730px;
}

.main-text {
  color: #333;
  font-family: Inter Bold;
  font-size: 68px;

  max-width: 940px;

  // @media screen and (max-width: 1500px) {
  //   font-size: 30px;
  // }

  // @media screen and (max-width: 1200px) {
  //   font-size: 24px;
  // }
}

.sub-text {
  color: #1F3A43;
  font-size: 42px;
  font-family: Inter SemiBold;

  margin-bottom: 10px;
}

.text {
  color: #333;
  font-family: Inter Medium;
  font-size: 22px;
  line-height: 160%;

  width: 655px;

  // @media screen and (max-width: 1500px) {
  //   font-size: 20px;
  // }

  // @media screen and (max-width: 1200px) {
  //   font-size: 14px;
  // }
}
</style>
