<template>
  <div class="event-panel">
    <div class="title">
      {{ $t("referralEvent.title") }}
    </div>
    <AuTable
      :columns="columns"
      :items="items"
    >
      <template #amount="{ item }">
        {{ item.amount }}
        <span v-if="item[symbolType]==='confirm'"> AUT</span>
        <span v-if="item[symbolType]==='pay'"> ₮</span>
      </template>
    </AuTable>
    <div
      class="footer"
      @click="goToReferral"
    >
      {{ $t("referralEvent.seeAll") }}
      <img
        class="arrow"
        src="/img/cabinet/arrow.svg"
        alt="arrow"
      >
    </div>
  </div>
</template>

<script setup>
import _ from "lodash";
import BigNumber from "bignumber.js";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n/dist/vue-i18n";
import { useRouter } from "vue-router";

const props = defineProps({
  referralEvents: {
    type: Object,
    default: () => {}
  }
});
const { t } = useI18n({});
const symbolType = ref(Symbol("type"));

const columns = computed(() => {
  return [
    { title: t("referralEvent.email"), name: "email" },
    { title: t("referralEvent.date"), name: "date" },
    { title: t("referralEvent.type"), name: "type" },
    { title: t("referralEvent.amount"), name: "amount", bold: true }];
});
const eventName = computed(() => {
  return {
    pay: t("referralEvent.pay"),
    confirm: t("referralEvent.confirm"),
    register: t("referralEvent.register")
  };
});
const items = computed(() => {
  return _.map(props.referralEvents, item => {
    const amount = BigNumber(item.amount);
    const ret = {
      id: item.id,
      email: item.email,
      date: item.createdAt,
      type: _.get(eventName.value, item.type, "-"),
      amount: amount.isNaN() ? "-" : amount.toFixed(2)
    };
    ret[symbolType.value] = item.type;
    return ret;
  });
});

const router = useRouter();
const goToReferral = () => {
  router.push({ name: "referral info" });
};
</script>

<style lang="scss">
.event-panel {
  width: 100%;
  box-sizing: border-box;
  border-radius: 15px;
  background: white;
  padding: 40px;

  @media screen and (max-width: 800px) {
    padding: 20px;
  }

  th {
    font-size: 1vw;

    @media (max-width: 800px) {
      padding: 5px 10px !important;
    }
  }

  .title {
    font-weight: 600;
    font-size: 2vw;
    margin-bottom: 30px;

    @media (max-width: 1600px) {
      font-size: 2.5vw;
    }

    @media (max-width: 800px) {
      font-size: 22px;
    }
  }

  .footer {
    margin-top: 50px;
    font-weight: 600;
    font-size: 1vw;
    color: #2ABA7C;
    display: flex;
    gap: 5px;
    justify-content: end;
    align-items: center;
    cursor: pointer;

    @media (max-width: 1600px) {
      font-size: 1.5vw;
    }

    @media (max-width: 800px) {
      font-size: 14px;
    }
  }

  .arrow {
    width: 26px;
  }
}
</style>
