<template>
  <div class="cookie-panel">
    <div class="content-wrapper">
      <h1 class="title">
        {{ $t("cookie.header") }}
      </h1>

      <div class="main">
        <h2>
          {{ $t("cookie.agree1") }}<a
            href="/privacy_policy.pdf"
            target="_blank"
          >Privacy Policy</a> {{ $t("cookie.agree2") }} <a
            href="/cookie_policy.docx"
            target="_blank"
          >Cookie Policy</a>, {{ $t("cookie.agree3") }}
        </h2>

        <h4>
          {{ $t("cookie.main") }}
        </h4>

        <div class="agree">
          <AuButton
            class="btn_agree"
            padding="12px 43px"
            font-size="18px"
            text-color-active="#fff"
            @click="$emit('accept')"
          >
            {{ $t("cookie.button") }}
          </AuButton>
          <AuButton
            class="btn_agree mobile"
            padding="8px 37px"
            font-size="14px"
            text-color-active="#fff"
            @click="$emit('accept')"
          >
            {{ $t("cookie.button") }}
          </AuButton>
        </div>
      </div>
    </div>

    <img
      class="close-icon"
      src="/img/modal/close.svg"
      alt=""
      @click="$emit('close')"
    >
    <img
      class="background-image left"
      src="/img/cookie/bg-left.svg"
    >
    <img
      class="background-image right"
      src="/img/cookie/bg-right.svg"
    >
  </div>
</template>

<style scoped lang="scss">
.cookie-panel {
  position: fixed;
  bottom: 0;
  z-index: 100000;
  left: 128px;
  right: 128px;
  display: flex;

  min-height: 285px;

  border-radius: 30px;
  background: linear-gradient(217deg, rgba(9, 75, 71, 0.85) 13.1%, rgba(17, 35, 41, 0.85) 64.38%);
  backdrop-filter: blur(6.550000190734863px);

  @media screen and (max-width: 1300px) {
    left: 0px;
    right: 0px;
    border-radius: 30px 30px 0 0;
    min-height: auto;
  }
  .content-wrapper {
    display: flex;
    gap: 63px;
    position: relative;
    padding: 30px 45px;

    @media screen and (max-width: 1400px) {
      gap: 20px;
    }

    @media screen and (max-width: 1300px) {
      gap: 5px;
      padding: 20px 15px;
      flex-direction: column;
    }

    .title {
      color: white;
      font-size: 28px;
      line-height: 30px;

      @media screen and (max-width: 1300px) {
        font-size: 20px;
      }
    }

    .main {
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media screen and (max-width: 1300px) {
        gap: 6px;
      }

      h2, h4 {
        color: white;
      }

      h2 {
        max-width: 923px;
        font-size: 20px;
        line-height: 30px;
        font-family: 'Inter Medium';

        @media screen and (max-width: 1300px) {
          font-size: 12px;
          line-height: 15px;
        }
      }

      h4 {
        max-width: 1078px;
        font-size: 15px;
        font-weight: 400;
        line-height: 23px;

        @media screen and (max-width: 1300px) {
          font-size: 11px;
          line-height: 14px;
        }
      }

      a {
        color: #4FE9A6;
      }

      .agree {
        margin-top: 10px;

        .mobile {
          display: none;
        }

        @media screen and (max-width: 1300px) {
          margin-top: 6px;

          .btn_agree:not(.mobile) {
            display: none;
          }
          .mobile {
            display: block;
          }
        }
      }
    }

  }

  .close-icon {
    position: absolute;
    right: 40px;
    top: 30px;
    cursor: pointer;

    @media screen and (max-width: 1300px) {
      right: 15px;
      top: 15px;
    }
  }

  .background-image {
    position: absolute;
    z-index: -1;

    &.left {
      left: 0;
      border-radius: 30px 0 0 30px;
      height: -webkit-fill-available;

      @media screen and (max-width: 1300px) {
        height: auto;
        border-radius: 30px 0 0 0;
      }
    }
    &.right {
      right: 0;
      border-radius: 0 30px 30px 0;
      height: -webkit-fill-available;

      @media screen and (max-width: 1300px) {
        height: auto;
        border-radius: 0 30px 0 0;
      }

      @media screen and (max-width: 876px) {
        display: none;
      }
    }
  }
}
</style>

<style lang="scss">
.cookie-panel {
  .agree {
    .btn_agree {
      .btn__text {
        @media (max-width: 560px) {
          white-space: wrap;
        }
        @media (max-width: 430px) {
          margin: 0px -28px;
        }
      }
    }
  }
}
</style>