<template>
  <div
    :style="inputStyle"
    class="input-wrapper"
  >
    <IMaskComponent
      :model-value="modelValue"
      class="input"
      radix="."
      :mask="Number"
      :max="10000"
      @update:modelValue="(e) => input(e)"
    />
  </div>
</template>

<script setup>
import { IMaskComponent } from "vue-imask";

defineProps({
  modelValue: {
    type: Number,
    default: null
  }
});

const emit = defineEmits(["update:modelValue"]);

const input = value => {
  emit("update:modelValue", value);
};
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.input-wrapper {
  width: 163px;
  @media screen and (max-width: 1200px) {
    width: 150px;
  }
}

.input {
  color: #1f3a43;
  font-size: 24px;

  border-radius: 5px;
  border: 1.5px solid #4fe9a6;
  background: #f7fbfd;
  box-shadow: 0px 4px 4px 0px rgba(61, 166, 128, 0.46) inset;
  width: 80px;
  text-align: end;
  padding: 10px 40px;

  @media screen and (max-width: 1200px) {
    width: 65px;
    font-size: 18px;
  }

  &::placeholder {
    color: #096f57;
  }
}

.input-icon {
  position: absolute;
  left: 30px;
  @media screen and (max-width: 1200px) {
    left: 15px;
    height: 24px;
  }
  &:has(+ textarea) {
    top: 24px;
  }
  & + input {
    padding: 22px 30px 22px 79px;
    @media screen and (max-width: 1200px) {
      font-size: 15px;
      padding: 15px 15px 15px 50px;
    }
  }
  & + textarea {
    padding: 22px 30px 22px 79px;
    @media screen and (max-width: 1200px) {
      font-size: 15px;
      padding: 22px 15px 15px 50px;
    }
  }
}

.text-area {
  width: 100%;
  border-radius: 12px;
  padding-top: 6px;
  resize: none;
  border: 0;
  outline: none;
  padding: 23px 30px;
  font-size: 28px;
  background: transparent;
  border-radius: 10px;
  color: white;
  border: 2px solid #4fe9a6;
  :focus {
    outline: none;
  }
  &::placeholder {
    color: #096f57;
  }
}
</style>
