<template>
  <div>
    <div class="transaction-title">
      {{ $t("transactions.title") }}
    </div>
    <div class="transaction-panel">
      <AuTable
        :columns="columns"
        :items="items"
      />
      <AuPagination
        v-if="totalItems > itemsPerPage"
        :total-items="totalItems"
        :items-per-page="itemsPerPage"
        @changePage="changePage"
      />
    </div>
  </div>
</template>

<script setup>
import _ from "lodash";
import auth from "@/auth";
import axios from "axios";
import BigNumber from "bignumber.js";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n({});

const page = ref(1);
const itemsPerPage = ref(30);
const totalItems = ref(0);
const transactions = ref(null);

const columns = computed(() => {
  return [
    { title: t("transactions.from"), name: "addressFrom" },
    { title: t("transactions.to"), name: "addressTo" },
    { title: t("transactions.amount"), name: "amount" },
    { title: t("transactions.network"), name: "blockChain" },
    { title: t("transactions.created"), name: "createdAt" },
    { title: t("transactions.type"), name: "type" }];
});
const items = computed(() => {
  return _.map(transactions.value, item => ({
    addressFrom: getShortAdr(item.addressFrom),
    addressTo: getShortAdr(item.addressTo),
    amount: getAmount(item.amount),
    blockChain: item?.blockChain,
    createdAt: getDate(item.createdAt),
    type: getType(item.type)
  }));
});

const getShortAdr = adr => {
  return /^0x[a-fA-F0-9]{40}$/.test(adr) ? adr.slice(0, 5) + "..." + adr.slice(-5) : "-";
};

const getType = type => {
  switch (type) {
  case "referral_pay":
    return "Реферальные выплаты";
  case "collect_usdt":
    return "Перевод";
  default:
    return "Стейкинг";
  }
};

const getAmount = amount => {
  const buf = BigNumber(amount);
  return buf.isNaN() ? "-" : buf.toString(10);
};

const getDate = createdAt => {
  if (_.isNil(createdAt)) {
    return;
  }
  const ret = new Date(createdAt);
  return ret.getDate() + "." + (ret.getMonth() + 1) + "." + ret.getFullYear() + " " +
    ret.getHours() + ":" + ret.getMinutes() + ":" + ret.getSeconds();
};

const changePage = async activePage => {
  page.value = activePage;
  await getTransactions();
};

const getTransactions = async () => {
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json",
      Authorization: `Bearer ${authMem?.token}`
    };
    const params = {
      page: page.value,
      itemsPerPage: itemsPerPage.value,
      userId: authMem?.id
    };
    const resp = await axios.get("/api/transactions/get_mixed", {
      headers: headers,
      params: params
    });
    transactions.value = resp.data["hydra:member"];
    totalItems.value = resp.data["hydra:totalItems"];
  }
  catch (e) {
    console.log(e);
  }
};

onMounted(async () => {
  await getTransactions();
});
</script>

<style scoped lang="scss">
.transaction-title {
  font-weight: 600;
  font-size: 56px;
  margin-bottom: 40px;
}
.transaction-panel {
  background-color: white;
  border-radius: 15px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 800px) {
  padding: 20px;
  }
}
</style>
