<template>
  <section class="scheme-section">
    <img
      src="/img/scheme/polygon-1.svg"
      alt="polygon"
    >

    <div class="scheme-big">
      <span class="title">
        Autentic Capital
      </span>

      <span class="step-1">
        {{ $t("schemaSection.title") }}
      </span>

      <div class="step-2">
        <span>
          {{ $t("schemaSection.step2.1") }}
        </span>

        <span>
          {{ $t("schemaSection.step2.2") }}
        </span>

        <span>
          {{ $t("schemaSection.step2.3") }}
        </span>
      </div>

      <span class="step-3">
        {{ $t("schemaSection.step3") }}
      </span>

      <div class="step-4">
        <div class="main">
          {{ $t("schemaSection.main") }}
        </div>

        <div class="block first">
          <span>
            ✓ {{ $t("schemaSection.block1.1") }}
          </span>

          <span>
            ✓ {{ $t("schemaSection.block1.2") }}
          </span>
        </div>

        <div class="block second">
          <span>
            ✓ {{ $t("schemaSection.block2.1") }}
          </span>

          <span>
            ✓ {{ $t("schemaSection.block2.2") }}
          </span>
        </div>
      </div>

      <div class="step-5">
        Autentic capital
      </div>

      <div class="step-6">
        {{ $t("schemaSection.step6") }}
      </div>

      <div class="step-7">
        ICO/STO
        <div>{{ $t("schemaSection.step7") }}</div>
      </div>

      <span class="step-8">
        {{ $t("schemaSection.step8") }}
      </span>

      <div class="step-9">
        <span>
          Pre-Sale
        </span>

        <span>
          Token Sale
        </span>

        <span>
          Post ICO
        </span>
      </div>

      <div class="step-10">
        <span class="top">
          {{ $t("schemaSection.sale") }}
        </span>

        {{ $t("schemaSection.step10") }}

        <span class="bottom">
          {{ $t("schemaSection.sale") }}
        </span>
      </div>
    </div>

    <img
      src="/img/scheme/scheme.svg"
      alt="scheme"
    >

    <div class="scheme-small">
      <span class="title">
        Autentic Capital
      </span>

      <span class="step-1">
        {{ $t("schemaSection.title") }}
      </span>

      <div class="step-2">
        <span>
          {{ $t("schemaSection.step2.1") }}
        </span>

        <span>
          {{ $t("schemaSection.step2.2") }}
        </span>

        <span>
          {{ $t("schemaSection.step2.3") }}
        </span>
      </div>

      <span class="step-3">
        {{ $t("schemaSection.step3") }}
      </span>

      <div class="step-4">
        <span class="main">
          {{ $t("schemaSection.main") }}
        </span>

        <div class="block">
          <span>
            ✓ {{ $t("schemaSection.block1.1") }}
          </span>

          <span>
            ✓ {{ $t("schemaSection.block1.2") }}
          </span>

          <span>
            ✓ {{ $t("schemaSection.block2.1") }}
          </span>

          <span>
            ✓ {{ $t("schemaSection.block2.2") }}
          </span>
        </div>
      </div>

      <div class="step-5">
        Autentic capital
      </div>

      <div class="step-6">
        {{ $t("schemaSection.step6") }}
      </div>

      <div class="step-7">
        ICO/STO
        <div>{{ $t("schemaSection.step7") }}</div>
      </div>

      <span class="step-8">
        {{ $t("schemaSection.step8") }}
      </span>

      <div class="step-9">
        <span>
          Pre-Sale
        </span>

        <span>
          Token Sale
        </span>

        <span>
          Post ICO
        </span>
      </div>

      <div class="step-10">
        <span class="top">
          {{ $t("schemaSection.sale") }}
        </span>

        {{ $t("schemaSection.step10") }}

        <span class="bottom">
          {{ $t("schemaSection.sale") }}
        </span>
      </div>
    </div>

    <img
      src="/img/scheme/scheme-small.svg"
      alt="scheme-small"
    >
  </section>
</template>

<script setup></script>

<style lang="scss" scoped>
.scheme-section {
  position: relative;
  display: flex;

  color: #4FE9A6;

  font-family: Inter Medium;

  ::selection {
    color: #fff;
  }

  .scheme-big {
    position: absolute;
    top: 5vw;
    width: 99.3%;
    display: flex;
    align-items: center;
    flex-direction: column;

    z-index: 1000;

    @media screen and (max-width: 850px) {
      display: none;
    }

    .title {
      color: #FFF;
      font-family: Inter SemiBold;
      font-size: 3.5vw;
    }

    .step-1 {
      font-size: 1.7vw;
      margin-top: 7.2vw;
    }

    .step-2 {
      display: flex;
      gap: 4vw;

      margin-top: 20vw;
      margin-left: 1vw;

      font-family: Inter;

      line-height: 2vw;

      span {
        font-size: 1.5vw;
        text-align: center;

        border-radius: 15px;
        background: linear-gradient(63deg, #244B4D 76.93%, #256366 106.82%);

        padding: 1.5vw 4vw;

        max-width: 11vw;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .step-3 {
      font-size: 2vw;

      position: absolute;
      top: 40vw;
    }

    .step-4 {
      width: 66vw;

      position: absolute;
      top: 49vw;
      left: 18vw;

      .main {
        font-size: 2vw;
        width: 20vw;

        line-height: 2.5vw;

        height: 7vw;
        margin-top: -1vw;
        display: flex;
        align-items: center;

        position: absolute;
      }

      .block {
        display: flex;
        flex-direction: column;

        font-family: Inter;

        position: absolute;

        font-size: 1.2vw;
        gap: 1vw;

        &.first {
          left: 22vw;
        }

        &.second {
          left: 45vw;
        }
      }
    }

    .step-5 {
      position: absolute;
      top: 66.5vw;

      line-height: 2.4vw;

      width: 14vw;
      font-size: 2vw;
      text-align: center;
    }

    .step-6 {
      position: absolute;
      top: 84.5vw;

      width: 50vw;
      font-size: 2vw;

      display: flex;
      justify-content: center;
      align-items: center;

      line-height: 2.4vw;

      height: 4vw;
    }

    .step-7 {
      position: absolute;
      top: 98.5vw;

      line-height: 2.4vw;

      width: 13vw;
      height: 5vw;
      font-size: 2vw;
      text-align: center;

      div {
        margin-top: 0.3vw;
      }
    }

    .step-8 {
      top: 123.5vw;

      width: 14vw;
      font-size: 1.8vw;
      position: absolute;
      left: 16.4vw;
      height: 5vw;

      line-height: 2.4vw;

      text-align: center;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .step-9 {
      position: absolute;
      top: 117.5vw;

      font-size: 1.8vw;
      text-align: center;

      display: flex;
      flex-direction: column;
      gap: 7.8vw;
    }

    .step-10 {
      top: 125vw;

      width: 14vw;
      font-size: 1.8vw;
      text-align: center;
      position: absolute;
      right: 16vw;
      bottom: 7vw;
      height: 6vw;

      line-height: 2.4vw;

      display: flex;
      align-items: center;

      >.top {
        position: absolute;
        text-align: left;

        top: -10vw;
        font-size: 1.2vw;
        left: 0vw;
        width: 17vw;
      }

      >.bottom {
        position: absolute;
        text-align: left;

        top: 13vw;
        font-size: 1.2vw;
        left: 0vw;
        width: 17vw;
      }
    }
  }

  .scheme-small {
    position: absolute;
    top: 10vw;
    width: 99.3%;
    align-items: center;
    flex-direction: column;

    z-index: 1000;

    display: none;

    @media screen and (max-width: 850px) {
      display: flex;
    }

    .title {
      color: #FFF;
      font-family: Inter SemiBold;
      font-size: 6vw;
    }

    .step-1 {
      font-size: 3vw;
      margin-top: 15vw;
    }

    .step-2 {
      display: flex;
      flex-direction: column;
      gap: 4vw;

      margin-top: 40vw;
      margin-left: 1vw;

      font-family: Inter;

      span {
        font-size: 3vw;

        height: 9.5vw;
        width: 77vw;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 15px;
        background: linear-gradient(63deg, #244B4D 76.93%, #256366 106.82%);
      }
    }

    .step-3 {
      font-size: 4vw;
      margin-top: 4vw;
    }

    .step-4 {
      margin-top: 17vw;
      width: 66vw;

      .main {
        font-size: 4vw;

        height: 7vw;
        margin-top: -1vw;
        display: flex;
        align-items: center;
      }

      .block {
        display: flex;
        flex-direction: column;

        font-family: Inter;

        font-size: 3vw;
        gap: 3.5vw;
        margin-top: 3vw;
      }
    }

    .step-5 {
      position: absolute;
      top: 185vw;

      width: 22vw;
      font-size: 4.5vw;
      text-align: center;
    }

    .step-6 {
      position: absolute;
      top: 216vw;

      width: 50vw;
      font-size: 3vw;
      text-align: center;
      margin-left: 10vw;
      line-height: 4.5vw;

      height: 13vw;
      display: flex;
      align-items: center;
    }

    .step-7 {
      position: absolute;
      top: 251vw;

      width: 20vw;
      height: 14vw;
      font-size: 4.5vw;
      text-align: center;

      div {
        margin-top: 0.3vw;
      }
    }

    .step-8 {
      position: absolute;
      top: 300vw;

      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      height: 10vw;
      width: 20vw;
      font-size: 2.8vw;
      left: 8.5vw;
    }

    .step-9 {
      position: absolute;
      top: 286vw;

      font-size: 3.5vw;
      text-align: center;

      display: flex;
      flex-direction: column;
      gap: 14.2vw;
    }

    .step-10 {
      position: absolute;
      top: 300vw;

      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      height: 10vw;
      width: 20vw;
      font-size: 2.8vw;
      text-align: center;
      right: 7.5vw;

      >.top {
        position: absolute;

        top: -19vw;
        font-size: 2.5vw;
        left: -1vw;
        text-align: left;
        width: 15vw;
      }

      >.bottom {
        position: absolute;

        top: 23vw;
        font-size: 2.5vw;
        left: -1vw;
        text-align: left;
        width: 15vw;
      }
    }
  }


  img {
    &[alt="scheme"] {
      width: 100%;

      @media screen and (max-width: 850px) {
        display: none;
      }
    }
    &[alt="scheme-small"] {
      width: 100%;
      display: none;

      @media screen and (max-width: 850px) {
        display: block;
      }
    }
    &[alt="polygon"] {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 10;
      width: 35vw;

      @media screen and (max-width: 1200px) {
        display: none;
      }
    }
  }
}
</style>
